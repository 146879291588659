import { useMemo } from 'react';
import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { Anchor } from '@sumup/circuit-ui';

import { type Country, termsURL } from '../../../services/i18n';

type TermsLinkParams = {
  country: Country;
};

export const TermsLink: FC<TermsLinkParams> = ({ country }) => {
  const intl = useIntl();
  const termsLink = useMemo(() => termsURL('terms', country), [country]);

  return (
    <Anchor href={termsLink}>
      {intl.formatMessage({
        defaultMessage: 'Terms and Conditions',
        description:
          'name of the link that links user to the site with Terms and Conditions',
      })}
    </Anchor>
  );
};
