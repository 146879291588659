import { Select, type SelectProps } from '@sumup/circuit-ui';
import {
  type ChangeEventHandler,
  type FunctionComponent,
  useCallback,
} from 'react';
import { useIntl } from 'react-intl';

import type { Country } from '../../services/i18n';

import * as CountrySelectService from './CountrySelectService';

export type CountrySelectProps = {
  label: string;
  countries?: Country[];
  onChange: ChangeEventHandler<HTMLSelectElement>;
  value: Country;
} & SelectProps;

/**
 * A simple select component for our supported countries.
 */
export const CountrySelect: FunctionComponent<CountrySelectProps> = ({
  label,
  countries,
  value,
  ...props
}) => {
  const intl = useIntl();
  const options = CountrySelectService.getCountryOptions(countries)
    .map(({ label: optionLabel, ...option }) => ({
      ...option,
      label: intl.formatMessage(optionLabel),
    }))
    .sort((optionA, optionB) =>
      Intl.Collator(intl.locale).compare(optionA.label, optionB.label),
    )
    .map(({ label: optionLabel, value: optionValue, ...o }) => (
      <option key={optionValue} value={optionValue} {...o}>
        {optionLabel}
      </option>
    ));

  const countryFlagRenderFn = useCallback(
    ({ className }: { className?: string }) => {
      const url = `https://circuit.sumup.com/icons/v2/flag_${value.toLowerCase()}_16.svg`;
      const alt = intl.formatMessage(
        {
          defaultMessage: 'Country flag: {country}',
          description:
            'Alt text describing an image of a flag in the country select element',
        },
        {
          country: intl.formatMessage(CountrySelectService.options[value]),
        },
      );

      return <img src={url} alt={alt} className={className} />;
    },
    [value, intl],
  );

  return (
    <Select
      label={label}
      renderPrefix={countryFlagRenderFn}
      value={value}
      {...props}
    >
      {options}
    </Select>
  );
};
