import { useMemo } from 'react';
import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { Anchor } from '@sumup/circuit-ui';

import { type Country, termsURL } from '../../../services/i18n';

type PrivacyLinkParams = {
  country: Country;
};

export const PrivacyLink: FC<PrivacyLinkParams> = ({ country }) => {
  const intl = useIntl();
  const privacyLink = useMemo(() => termsURL('privacy', country), [country]);

  return (
    <Anchor href={privacyLink}>
      {intl.formatMessage({
        defaultMessage: 'Privacy Policy',
        description:
          'name of the link that links user to the site with privacy policy',
      })}
    </Anchor>
  );
};
